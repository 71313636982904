import VueMatomo from 'vue-matomo'

export function getMatomoInitArgs (router) {
  return [VueMatomo, {
    // Configure your matomo server and site by providing
    host: process.env.VUE_APP_MATOMO_URL || 'https://www-stats2.knipp.de',
    siteId: process.env.VUE_APP_MATOMO_SITE_ID || 7,

    // Changes the default .js and .php endpoint's filename
    // Default: 'matomo'
    // trackerFileName: 'matomo',

    // Overrides the autogenerated tracker endpoint entirely
    // Default: undefined
    // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

    // Enables automatically registering pageviews on the router
    router: router,

    // Enables link tracking on regular links. Note that this won't
    // work for routing links (ie. internal Vue router links)
    // Default: true
    // enableLinkTracking: true,

    // Require consent before sending tracking information to matomo
    // Default: false
    // requireConsent: false,

    // Whether to track the initial page view
    // Default: true
    // trackInitialView: true,

    // Run Matomo without cookies
    // Default: false
    // disableCookies: false,

    // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
    // Default: false
    // enableHeartBeatTimer: false,

    // Set the heartbeat timer interval
    // Default: 15
    // heartBeatTimerInterval: 15,

    // Whether or not to log debug information
    // Default: false
    debug: process.env.NODE_ENV !== 'production'

    // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
    // Default: undefined
    // userId: undefined
  }]
}
